<script>
import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  name: "SavingsExplorerChart",
  extends: Doughnut,
  mixins: { reactiveProp },
  props: {
    values: Array,
    colors: Array,
    labels: Array
  },
  data() {
    return {
      // screen widths
      sm: false,
      md: false,
      lg: true
    };
  },
  computed: {
    chartData: function () {
      return {
        labels: this.labels,
        datasets: [
          {
            backgroundColor: this.colors,
            borderColor: "white",
            borderWidth: 2,
            data: this.values
          }
        ]
      };
    },
    chartOptions: function () {
      return {
        cutoutPercentage: 70,
        layout: {
          padding: {
            right: this.sm ? 0 : 30,
            left: this.sm ? 0 : 30
          }
        },
        plugins: {
          datalabels: {
            display: false
          }
        },
        tooltips: {
          enabled: !this.sm ? true : false,
          backgroundColor: "rgb(236,240,242, 0.95)",
          titleFontSize: 16,
          titleFontFamily: "geomanistmedium",
          titleFontColor: "#3A3C3F",
          cornerRadius: 10,
          displayColors: false,
          callbacks: {
            title: (ctx) => this.labels[ctx[0].index],
            label: () => ""
          }
        },
        animation: {
          duration: 2000,
          animateScale: true
        },
        legend: {
          display: false
        }
      };
    }
  },
  async mounted() {
    // Set the screen sizing
    let screenWidth = window.screen.width;
    this.sm = screenWidth < 767;
    this.md = !this.sm && screenWidth < 1200;
    this.lg = !this.sm && !this.md;

    // Render the chart
    this.renderChart(this.chartData, this.chartOptions);
  }
};
</script>