<template>
  <div id="savings-explorer-overview" v-if="total && total.clients > 0" :class="{ compact: admin }">
    <SavingsExplorerNoQuoteTable
      :reportCount="total.clients"
      :supplyTypeSummary="total.supplyTypeSummary"
    />
    <p v-if="admin && total.userEmail">
      Exploring savings for <strong>{{ total.userEmail }}</strong>
    </p>
    <b-row class="savings-breakdown">
      <b-col cols="12" xl="6">
        <div class="chart-title">
          <h4>Analysis results</h4>
          <p v-if="!admin" v-html="analysisChartDescription()"></p>
          <p v-if="total.clients > 0" class="d-none d-lg-block">
            We could not provide a quote for every client in each area we cover. If you would like
            more information about this we provide an
            <a id="analysisSummary" v-b-modal.savingsExplorerNoQuoteTable>analysis breakdown</a>.
          </p>
        </div>

        <div class="report-chart">
          <div class="chart-legend">
            <ul>
              <li v-for="(label, index) in reportLabels" :key="index">
                <span :style="`background-color: ${reportColors[index]}`"></span>
                {{ label }}
              </li>
            </ul>
          </div>
          <div class="chart">
            <div class="inside-text" v-if="total.organisationsCount">
              <div class="number">{{ total.clients }}/{{ total.organisationsCount }}</div>
              <p>{{ total.clients == 1 ? "Analysis" : "Analyses" }}<br />completed</p>
            </div>
            <div v-else class="inside-text">
              <div class="number">{{ total.clients }}</div>
              <p>{{ total.clients == 1 ? "Organisation" : "Organisations" }}<br />analysed</p>
            </div>
            <SavingsExplorerChart
              :colors="reportColors"
              :values="Object.values(simplifiedReportStateCount)"
              :labels="reportLabels"
            />
          </div>
        </div>
      </b-col>
      <b-col cols="12" xl="6" v-if="total.quotableClients > 0">
        <div class="chart-title pt-5" v-if="total.quotableClients > 0">
          <h4>Breakdown of savings per area</h4>
          <p v-if="!admin" v-html="savingsChartDesc()"></p>
        </div>
        <div class="savings-chart">
          <div class="chart-legend">
            <ul>
              <li v-for="(label, index) in savingLabels" :key="index">
                <span :style="`background-color: ${savingColors[index]}`"></span>
                {{ label }}
              </li>
            </ul>
          </div>
          <div class="chart" v-if="total.quotableClients > 0">
            <div class="inside-text">
              <div class="number">
                {{ formatCurrency(total.totalSavings) }}
              </div>
              <p>Savings found</p>
            </div>
            <SavingsExplorerChart
              :colors="savingColors"
              :values="Object.values(total.savingPerArea)"
              :labels="savingLabels"
              @chart="chart = $event"
            />
          </div>
        </div>
      </b-col>
      <b-col cols="12" v-if="admin && total.totalCommission">
        <div class="chart-title pt-5">
          <h4>Breakdown of commission per area</h4>
        </div>
        <div class="savings-chart">
          <div class="chart-legend">
            <ul>
              <li v-for="(label, index) in commissionLabels" :key="index">
                <span :style="`background-color: ${commissionColors[index]}`"></span>
                {{ label }}
              </li>
            </ul>
          </div>
          <div class="chart">
            <div class="inside-text">
              <div class="number">
                {{ formatCurrency(total.totalCommission) }}
              </div>
              <p>Commission <br />available 🤑</p>
            </div>
            <SavingsExplorerChart
              :colors="commissionColors"
              :values="Object.values(total.commissionPerArea)"
              :labels="commissionLabels"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SupplyTypes from "@/helper/supplytypehelper";
import FormatHelper from "@/helper/formathelper";
import SavingsExplorerChart from "@/components/SavingsExplorerChart";
import SavingsExplorerNoQuoteTable from "@/components/SavingsExplorerNoQuoteTable";

export default {
  name: "SavingsExplorerOverview",
  props: {
    total: Object,
    summaries: Array,
    admin: Boolean
  },
  components: {
    SavingsExplorerChart,
    SavingsExplorerNoQuoteTable
  },
  data() {
    return {
      showNoQuoteModal: false,
      chart: null,
      screenWidth: window.screen.width,
      reportColors: ["#4a65b4", "#6aa6d2", "#9fd7ea", "#dbf3f9"],
      savingColors: [
        "#4a65b4",
        "#6aa6d2",
        "#9fd7ea",
        "#dbf3f9",
        "#f8ffbc",
        "#fee98a",
        "#ffb055",
        "#ff602c",
        "#ea0000"
      ],
      commissionColors: [
        "#497d29",
        "#589632",
        "#67b03a",
        "#7fc355",
        "#9dda77",
        "#c0f279",
        "#cbf392",
        "#d8f6ac",
        "#e5f9c6"
      ]
    };
  },
  methods: {
    formatReportState(state) {
      switch (state) {
        case "complete":
          return "Report complete";
        default:
          return "No savings";
      }
    },
    formatType(type) {
      if (type == "CUSTOM") {
        return "Other";
      } else return FormatHelper.formatCoboldCase(type);
    },
    formatCurrency(value) {
      if (value) {
        return this.$currencySymbol + FormatHelper.formatNumberToDisplay(value);
      } else {
        return this.$currencySymbol + "0";
      }
    },
    analysisChartDescription() {
      let totalAnalysis = this.total.clients;
      let totalClient = this.total.organisationsCount;
      let reportCompl = this.total.reportStateCount.complete;
      let noSavings = totalAnalysis - reportCompl;

      let desc = "We have completed analyses for ";

      if (totalAnalysis == totalClient) {
        desc += `all ${totalClient} of your clients. `;
      } else {
        desc += `${totalAnalysis} of ${totalClient} clients. `;
      }

      // At least one report complete
      if (reportCompl > 0) {
        if (noSavings == 0) desc += "<br>";
        desc += `We have produced ${reportCompl} ${
          reportCompl == 1 ? "report" : "reports"
        }, with a total of <strong>${this.formatCurrency(
          this.total.totalSavings
        )}</strong> savings found. `;
      }
      // At least one report with no savings
      if (noSavings > 0) {
        desc += `We haven't been able to produce a savings report for ${noSavings} of your clients.  `;
      }
      return desc;
    },
    savingsChartDesc() {
      let reportCompl = this.total.reportStateCount.complete;
      let savingPerArea = Object.entries(this.total.savingPerArea).filter((entry) => entry[1] > 0);

      let desc = `From ${reportCompl} ${
        reportCompl == 1 ? " savings report" : " savings reports"
      } created, we identified total savings of <strong>${this.formatCurrency(
        this.total.totalSavings
      )}</strong> `;

      // 1 spend area
      if (savingPerArea.length == 1) {
        desc += `for ${this.formatType(savingPerArea[0][0]).toLowerCase()}.`;
        return desc;
        // Multiple spend areas
      } else {
        desc += `across ${savingPerArea.length} spend areas.<br>`;
      }

      // Describe area with most savings
      if (savingPerArea.length > 1) {
        desc += `The largest savings area was ${this.formatType(
          savingPerArea[0][0]
        ).toLowerCase()}, with <strong>${this.formatCurrency(
          savingPerArea[0][1]
        )}</strong> of savings found.`;

        // Mention other areas
        if (savingPerArea.length > 2) {
          if (this.total.clients - reportCompl > 0) desc += "<br>";
          desc += " We also found savings of ";
          savingPerArea.forEach(([area, sav], index, arr) => {
            if (index > 0) {
              desc += `<strong>${this.formatCurrency(sav)}</strong> in ${this.formatType(
                area
              ).toLowerCase()}`;
              if (index == arr.length - 2) {
                desc += " and ";
              } else if (index < arr.length - 1) {
                desc += ", ";
              } else {
                desc += ".";
              }
            }
          });
        }
      }
      return desc;
    }
  },
  computed: {
    simplifiedReportStateCount: function () {
      let simplifiedReportStateCount = { noSavings: 0 };
      for (const [state, value] of Object.entries(this.total.reportStateCount)) {
        if (state == "complete") {
          simplifiedReportStateCount[state] = this.total.reportStateCount[state];
        } else {
          simplifiedReportStateCount.noSavings = simplifiedReportStateCount.noSavings + value;
        }
      }
      return simplifiedReportStateCount;
    },
    savingLabels: function () {
      let supplyTypes = SupplyTypes.supplyTypes();
      let labels = Object.entries(this.total.savingPerArea).map(([type, sav]) => {
        let label = `${this.formatType(type)} - ${this.formatCurrency(sav)}`;
        if (this.admin && sav > 0) {
          label += ` - ${((sav * 100) / this.total.totalSavings).toFixed(2)}%`;
        }
        return label;
      });
      supplyTypes.forEach((st) => {
        if (this.total.savingPerArea[st] == null && (st != "CUSTOM" || this.admin))
          labels.push(this.formatType(st) + " - " + this.$currencySymbol + "0");
      });
      return labels;
    },
    reportLabels: function () {
      let labels = Object.entries(this.simplifiedReportStateCount).map(([state, num]) => {
        let label = `${this.formatReportState(state)} - ${num}`;
        if (this.admin && num > 0) {
          label += ` - ${((num * 100) / this.total.clients).toFixed(2)}%`;
        }
        return label;
      });
      ["complete", "noSavings"].forEach((state) => {
        if (this.simplifiedReportStateCount[state] == null) {
          labels.push(this.formatReportState(state) + " - 0");
        }
      });
      return labels;
    },
    commissionLabels: function () {
      if (this.admin) {
        let supplyTypes = SupplyTypes.supplyTypes();
        let labels = Object.entries(this.total.commissionPerArea).map(([type, sav]) => {
          let label = `${this.formatType(type)} - ${this.formatCurrency(sav)}`;
          if (this.admin && sav > 0) {
            label += ` - ${((sav * 100) / this.total.totalCommission).toFixed(2)}%`;
          }
          return label;
        });
        supplyTypes.forEach((st) => {
          if (this.total.commissionPerArea[st] == null && (st != "CUSTOM" || this.admin))
            labels.push(this.formatType(st) + " - " + this.$currencySymbol + "0");
        });
        return labels;
      } else return null;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#savings-explorer-overview {
  #analysisSummary {
    color: $color-primary-blue-dark1;
    text-decoration: underline;
    &:hover {
      color: $color-primary-blue-dark2;
    }
  }
  h5,
  .inside-text p {
    color: $color-font-headings;
  }
  .savings-breakdown {
    .chart {
      width: 70vw;
      max-width: 15rem;
      margin: auto;
      position: relative;
      // Text inside the charts
      .inside-text {
        position: absolute;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .number {
          text-align: center;
          font-family: geomanistmedium, Roboto, sans-serif;
          font-size: 1.6rem;
        }
        p {
          text-align: center;
          font-size: 0.9rem !important;
          line-height: 1.3rem;
          margin: 0;
        }
      }
    }
    // Chart legend
    .chart-legend {
      cursor: default;
      ul {
        padding: 0 0.5rem;
      }
      li {
        list-style: none;
        font-family: geomanistmedium, Roboto, sans-serif;
        font-size: 0.95rem;
        span {
          display: inline-block;
          width: 2rem;
          height: 1rem;
          margin-right: 1rem;
        }
      }
    }
    // Chart title and description
    .chart-title {
      p {
        line-height: 1.6rem;
        br {
          margin-bottom: 0.3rem;
        }
      }
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }
  }
  @media screen and (min-width: 767px) {
    .savings-chart,
    .report-chart {
      display: flex;
      flex-flow: row-reverse;
      align-items: center;
      justify-content: flex-end;
    }
    .chart {
      max-width: 19rem !important;
      margin: 0 !important;
      margin-right: 2rem !important;
    }
    p {
      max-width: 80vw;
    }
  }
  @media screen and (min-width: 1200px) {
    .savings-breakdown {
      .col-xl-6 {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
      }
      p {
        max-width: 52rem;
      }
    }
    .chart-title {
      padding-top: 0rem !important;
    }
    .chart-legend li {
      font-size: 1.1rem !important;
    }
    // Compact styling (admin)
    &.compact {
      .savings-breakdown {
        & > div {
          flex-basis: 33%;
        }
        .chart {
          max-width: 17rem !important;
          align-self: flex-start;
        }
        .chart-title {
          display: none;
        }
        .chart-legend {
          margin-left: -2rem;
        }
        li {
          font-size: 1rem !important;
        }
      }
    }
  }
}
</style>