<template>
  <div id="savings-explorer-table">
    <!-- Don't display table on small screens -->
    <div class="small-screen-text text-center pt-5 pb-3">
      A detailed savings breakdown is available in desktop view.
    </div>
    <div class="table-wrapper">
      <!-- Sticky table header -->
      <div class="sticky-table-header border">
        <!-- Organisation earch, filter slot and per page control -->
        <div class="header-filters">
          <div class="filter-group">
            <b-form-group label-for="savSummaryFilterOrgName">
              <b-form-input
                class="reducer-search-input"
                placeholder="Search organisations"
                id="savSummaryFilterOrgName"
                v-model="orgNameSearch"
              />
            </b-form-group>
            <slot name="filter"></slot>
            <div class="ml-auto d-flex">
              <b-select
                v-if="filteredSummaries.length > perPage"
                class="reducer-select small-select mr-2"
                v-model="perPage"
                :options="perPageOptions"
              />
              <b-pagination
                v-show="filteredSummaries.length > perPage"
                v-model="currentPage"
                class="reducer-pagination"
                :total-rows="filteredSummaries.length"
                :per-page="perPage"
                :first-text="'\u00AB'"
                :prev-text="'\u2039'"
                :next-text="'\u203A'"
                :last-text="'\u00BB'"
                :ellipsis-text="'\u2026'"
              />
            </div>
          </div>
        </div>
        <div>
          <!-- Table header columns -->
          <b-table-simple
            id="savings-table-columns"
            :class="{
              'no-custom-type': !showCustomTypeColumn,
              'show-comm': admin,
              'hide-comm': !admin
            }"
          >
            <b-thead>
              <!-- First header row -->
              <b-tr>
                <b-th colspan="5" v-if="admin"></b-th>
                <b-th
                  v-else
                  v-for="(column, index) in tableHeaders"
                  :key="index"
                  :aria-sort="sortBy == column.field ? sortDirection : 'none'"
                  :class="'cell-' + column.field"
                  @click="changeSorting(column.field)"
                >
                  {{ column.text.toUpperCase() }}
                </b-th>
                <b-th
                  v-for="area in supplyTypes"
                  :key="area"
                  class="cell-savings-area"
                  :colspan="admin ? 2 : 1"
                >
                  {{ formatSupplyTypeHeader(area).toUpperCase() }}
                </b-th>
              </b-tr>
              <!-- Second header row (admin only) -->
              <b-tr class="bottom-header" v-if="admin">
                <b-th
                  v-for="(column, index) in adminHeaders"
                  :key="index"
                  :aria-sort="sortBy == column.field ? sortDirection : 'none'"
                  :class="'cell-' + column.field"
                  @click="changeSorting(column.field, column.sortSavingCommission, column.sortType)"
                >
                  {{ column.text }}
                </b-th>
              </b-tr>
            </b-thead>
          </b-table-simple>
        </div>
      </div>
      <!-- Table content -->
      <b-table-simple
        id="savings-table"
        outlined
        fixed
        :class="{
          'no-custom-type': !showCustomTypeColumn,
          'show-comm': admin,
          'hide-comm': !admin
        }"
      >
        <b-tbody>
          <b-tr v-if="!summaries || summaries.length == 0">
            <p class="text-center my-4">No results</p>
          </b-tr>
          <b-tr
            v-for="summary in paginatedSummaries"
            :key="summary.organisationId"
            :class="summary.state != 'complete' ? 'no-report-row' : ''"
          >
            <!-- Name -->
            <b-td :id="summary.organisationId" class="cell-orgName">
              {{ formatOrgName(summary.orgName) }}
              <b-popover
                v-if="admin"
                :target="summary.organisationId"
                triggers="hover"
                placement="bottomright"
              >
                <template #title>First User</template>
                {{ summary.orgFirstUserEmail }}
              </b-popover>
            </b-td>

            <!-- Report State -->
            <b-td class="cell-state">
              {{ formatReportState(summary.state) }}
              <router-link
                v-if="summary.state == 'complete'"
                :to="{ name: 'savings', params: { organisationId: summary.organisationId } }"
                target="_blank"
              >
                <font-awesome-icon :icon="['fas', 'external-link']" class="ml-1" />
              </router-link>
              <font-awesome-icon
                v-if="summary.shared && admin"
                :icon="['fas', 'share-alt-square']"
                class="text-success ml-2"
                v-b-popover.hover.top="'First shared on ' + formatDate(summary.shared)"
              />
            </b-td>

            <!-- Published -->
            <b-td class="cell-createdDate">
              {{ formatDate(summary.createdDate) }}
            </b-td>

            <!-- Total Savings -->
            <b-td class="cell-totalSavings">
              {{ hasReport(summary) ? formatCurrency(summary.totalSavings) : null }}
            </b-td>

            <!-- Total Commission -->
            <b-td class="cell-totalCommission" v-if="admin">
              {{ hasReport(summary) ? formatCurrency(summary.totalCommission) : null }}
            </b-td>

            <!-- Savings per area -->
            <b-td
              v-for="(areaSaving, index) in getSavingPerAreaFields(summary.supplySavingMap)"
              :key="index"
              :colspan="areaSaving.colspan"
              :class="[areaSaving.class, 'cell-savings-area']"
            >
              {{ areaSaving.value }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import SupplyTypes from "@/helper/supplytypehelper";
import FormatHelper from "@/helper/formathelper";
import ApiHelper from "@/helper/apihelper";
export default {
  name: "SavingsExplorerTable",
  props: {
    summaries: {
      type: Array,
      required: true
    },
    total: {
      type: Object,
      required: true
    },
    admin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      perPage: 50,
      currentPage: 1,
      orgNameSearch: "",
      screenWidth: window.screen.width,

      sortBy: "createdDate",
      sortDirection: "descending",
      sortSavingCommission: false,
      sortByType: null, // ELECTRICITY, GAS
      sortByTypeField: "saving", // saving, commission

      perPageOptions: [
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
        { value: 500, text: "500 / page" },
        { value: ApiHelper.ALL_ITEMS, text: "All items" }
      ]
    };
  },
  computed: {
    supplyTypes() {
      if (this.showCustomTypeColumn) {
        return SupplyTypes.supplyTypes();
      } else {
        return SupplyTypes.supplyTypes().filter((t) => t != "CUSTOM");
      }
    },
    filteredSummaries() {
      return this.summaries.filter((s) =>
        s.orgName ? s.orgName.toLowerCase().includes(this.orgNameSearch) : false
      );
    },
    paginatedSummaries() {
      return this.filteredSummaries.slice(
        this.perPage * (this.currentPage - 1),
        this.perPage * this.currentPage
      );
    },
    // Don't show custom type if user is not an admin and doesn't have any custom type data
    showCustomTypeColumn() {
      if (!this.admin && this.total) {
        return this.total.supplyTypes.includes("CUSTOM");
      } else return true;
    },
    adminHeaders() {
      if (this.admin) {
        let headers = [
          { text: "NAME", field: "orgName" },
          { text: "STATE", field: "state" },
          { text: "DATE", field: "createdDate" },
          { text: "T SAV", field: "totalSavings" },
          { text: "T COM", field: "totalCommission" }
        ];
        this.supplyTypes.forEach((type) => {
          headers.push({
            text: "Sav",
            field: `supplySavingMap.${type}.saving`,
            sortType: type,
            sortSavingCommission: true
          });
          headers.push({
            text: "Com",
            field: `supplySavingMap.${type}.commission`,
            sortType: type,
            sortSavingCommission: true
          });
        });
        return headers;
      } else return null;
    },
    tableHeaders() {
      let short = this.screenWidth < 1200;
      return [
        { text: "Name", field: "orgName" },
        { text: short ? "State" : "Report State", field: "state" },
        { text: short ? "Date" : "Published", field: "createdDate" },
        { text: "Savings", field: "totalSavings" }
      ];
    }
  },
  methods: {
    formatOrgName: (name) => FormatHelper.orgShortName(name),
    formatDate: (date) => FormatHelper.formatDate(date),

    // Format supply type for the header, shorten name for smaller screens
    formatSupplyTypeHeader(type) {
      if (type == "ELECTRICITY" && this.screenWidth < 1200) {
        return "Elec";
      } else if (type == "TELECOMS" && this.screenWidth < 1200) {
        return "Telec";
      } else if (type == "CARD_PAYMENTS") {
        return "Card Paym";
      } else if (type == "CUSTOM") {
        return "Other";
      }
      return SupplyTypes.toDisplay(type);
    },

    formatReportState(state) {
      switch (state) {
        case "complete":
          return "Complete";
        case "noneFound":
          return "No Savings";
        case "noneFoundNoMatches":
          return "No Savings";
        case "noneFoundInsufficientData":
          return "No Savings";
      }
    },

    formatCurrency(value) {
      if (value) {
        return this.$currencySymbol + FormatHelper.formatNumberToDisplay(value);
      } else {
        return this.$currencySymbol + "0";
      }
    },

    formatNoQuote(noQuote) {
      switch (noQuote) {
        case "IN_CONTRACT":
          return "In contract";
        case "NOT_ENOUGH_DATA":
          return "Not enough data";
        case "ON_GOOD_DEAL":
          return "On good deal";
      }
    },

    hasReport(summary) {
      return summary.state == "complete";
    },

    changeSorting(column, sortSavingCommission, sortType) {
      if (this.sortBy == column) {
        this.sortDirection = this.sortDirection == "ascending" ? "descending" : "ascending";
      } else {
        this.sortBy = column;
      }
      if (sortSavingCommission) {
        this.sortSavingCommission = true;
        this.sortByType = sortType;
        this.sortByTypeField = column.includes("saving") ? "saving" : "commission";
      } else {
        this.sortSavingCommission = false;
      }
      this.sortSummaries();
    },

    sortSummaries() {
      if (this.sortDirection == "ascending") {
        this.summaries.sort((s1, s2) =>
          this.sortCompareSummaries(s1, s2, this.sortBy, this.sortBy == "orgName")
        );
      } else {
        this.summaries
          .sort((s1, s2) =>
            this.sortCompareSummaries(s1, s2, this.sortBy, this.sortBy == "orgName")
          )
          .reverse();
      }
    },

    sortCompareSummaries(s1, s2, propName, isCaseInsensitive) {
      if (isCaseInsensitive) {
        return s2[propName].toLowerCase() < s1[propName].toLowerCase() ? 1 : -1;
      } else {
        const hasSavComm = (s) =>
          s.supplySavingMap[this.sortByType] &&
          s.supplySavingMap[this.sortByType][this.sortByTypeField] != null;

        if (this.sortSavingCommission && !(hasSavComm(s1) && hasSavComm(s2))) {
          if (hasSavComm(s2)) {
            return -1;
          } else if (hasSavComm(s1)) {
            return 1;
          } else return 0;
        } else {
          if (this.sortSavingCommission) {
            return s2.supplySavingMap[this.sortByType][this.sortByTypeField] <
              s1.supplySavingMap[this.sortByType][this.sortByTypeField]
              ? 1
              : -1;
          } else {
            return s2[propName] < s1[propName] ? 1 : -1;
          }
        }
      }
    },

    // Get saving area table cells
    getSavingPerAreaFields(savingPerArea) {
      let tableFields = [];
      this.supplyTypes.forEach((area) => {
        let areaSaving = savingPerArea[area];
        if (areaSaving) {
          if (!areaSaving.noQuote && areaSaving.saving == null) {
            tableFields.push({
              value: "No spend found",
              colspan: this.admin ? 2 : 1,
              class: "no-spend-area"
            });
          } else if (areaSaving.noQuote) {
            tableFields.push({
              value: this.formatNoQuote(areaSaving.noQuote),
              colspan: this.admin ? 2 : 1,
              class: "no-savings-area"
            });
          } else {
            tableFields.push({
              value: this.formatCurrency(areaSaving.saving)
            });
            if (this.admin) {
              tableFields.push({
                value: this.formatCurrency(areaSaving.commission),
                class: "cell-comm"
              });
            }
          }
        } else {
          tableFields.push({
            value: "No spend found",
            colspan: this.admin ? 2 : 1,
            class: "no-spend-area"
          });
        }
      });
      return tableFields;
    }
  },
  watch: {
    total() {
      this.sortSummaries();
    }
  },
  mounted() {
    this.sortSummaries();
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";

#savings-explorer-table {
  $color-comms: $color-pink-lighter9;
  $color-no-sav: $color-grey-lighter5;
  $color-header: $color-grey-lighter3;
  $table-border: 1px solid $color-grey-lighter2;

  .orgFirstUserEmail {
    display: block;
    color: $color-grey;
  }
  #savings-table {
    // Remove top border
    border-top: none !important;
    tr:first-child {
      td {
        border-top: none;
      }
    }
  }
  // Fixed cell widths (non-admin)
  .hide-comm {
    .cell-orgName {
      width: 10%;
      word-wrap: break-word;
    }
    .cell-state,
    .cell-totalSavings {
      width: 10%;
      min-width: 10%;
    }
    .cell-createdDate {
      width: 7%;
    }
    .cell-savings-area {
      width: 7%;
    }
    // Don't show custom type column
    .no-custom-type {
      .cell-savings-area {
        width: 7.875% !important;
      }
    }
  }
  // Admin table styling
  .show-comm {
    // Fixed cells widths
    td {
      font-size: 0.85rem;
    }
    .cell-orgName {
      max-width: 7%;
      width: 7%;
      word-wrap: break-word;
    }
    td.cell-orgName {
      font-size: 0.9rem !important;
    }
    .cell-state {
      width: 6%;
    }
    .cell-createdDate {
      width: 5%;
    }
    .cell-totalSavings,
    .cell-totalCommission {
      width: 5%;
    }
    .cell-savings-area.no-spend-area,
    th.cell-savings-area {
      width: 8% !important;
    }
    // Style the headers
    .cell-comm,
    .cell-sav,
    .cell-com .cell-savings-area:not(.no-spend-area) {
      width: 4%;
    }
    .cell-sav {
      border-left: $table-border !important;
    }
    .cell-com {
      border-right: $table-border !important;
    }
    .cell-sav,
    .cell-com {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    th.cell-savings-area {
      border-right: $table-border !important;
      border-left: $table-border !important;
    }
    th {
      padding-bottom: 0.5rem !important;
      border-bottom: $table-border !important;
    }
    // Table cell padding
    td,
    th {
      padding: 1.2rem 0.6rem;
    }
  }
  // Cell styles
  td,
  th {
    font-size: 0.95rem;
    text-align: center;
    vertical-align: middle;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
  // Commission cell
  td.cell-comm {
    background-color: $color-comms;
    border-radius: 7px;
  }
  // Coloring no quote report rows and
  .no-report-row {
    background-color: $color-no-sav;
    .cell-comm {
      background-color: $color-no-sav !important;
    }
  }
  .table-wrapper {
    position: relative;
    // Prevent jumping screen when filtering
    min-height: 100vh;
  }
  .sticky-table-header {
    position: sticky;
    top: 0;
    right: 0;
    background-color: $color-header;
    .header-filters {
      padding: 0.5rem;
      border-bottom: $table-border;
      .filter-group {
        display: flex;
        align-items: center;
        .form-group {
          width: 15rem;
          margin-right: 1rem;
          margin-bottom: 0;
          &:first-child {
            width: 18rem;
          }
        }
        .small-select {
          margin-left: auto;
          max-width: 9rem;
        }
      }
    }
    th[role="columnheader"] {
      padding-top: 1.1rem;
      padding-bottom: 1.1rem;
      border: none;
      font-size: 0.8rem;
      word-wrap: break-word;
    }
  }
  .no-savings-area {
    font-size: 0.85rem;
  }
  .no-spend-area {
    color: $color-font-para;
    font-size: 0.8rem !important;
  }
  #savings-table-columns {
    margin-bottom: 0;
  }
  .reducer-pagination {
    background-color: white;
  }
  @media screen and (max-width: 1000px) {
    .small-screen-text {
      display: block;
    }
    .table-wrapper {
      display: none;
    }
  }
  @media screen and (min-width: 1001px) {
    .small-screen-text {
      display: none;
    }
    .table-wrapper {
      display: block;
    }
  }
}
</style>
