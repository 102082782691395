<template>
  <b-modal
    body-class="p-4"
    size="lg"
    id="savingsExplorerNoQuoteTable"
    ok-only
    hide-header
  >
    <h2 class="mt-2">Analysis breakdown</h2>
    <h4>Why we could not quote</h4>
    <p>
      This table shows a summary of how many clients were made offers in each area. Where no offer
      was made, we provide a reason that no quote could be provided. An explanation of each reason
      is found below the table.
    </p>
    <p>
      We have completed analysis for <strong>{{ reportCount }}</strong> clients in total.
    </p>
    <b-table-simple borderless hover class="my-4">
      <b-thead class="text-center">
        <b-tr>
          <b-th colspan="2"></b-th>
          <b-th colspan="4" variant="secondary">Reason we could not quote</b-th>
        </b-tr>
        <b-tr>
          <b-th class="text-left">Area</b-th>
          <b-th variant="success">Offers<br />made</b-th>
          <b-th
            >Not enough<br />
            data</b-th
          >
          <b-th>In<br />contract</b-th>
          <b-th>On a<br />good deal</b-th>
          <b-th>No<br />Spend</b-th>
        </b-tr>
      </b-thead>
      <b-tbody class="text-center">
        <b-tr v-for="(value, name) in supplyTypeSummary" :key="name">
          <b-td class="text-left">{{ formatCoboldCase(name) }}</b-td>
          <b-td variant="success">{{ value["DEALS"] }}</b-td>
          <b-td>{{ value["NOT_ENOUGH_DATA"] }}</b-td>
          <b-td>{{ value["IN_CONTRACT"] }}</b-td>
          <b-td>{{ value["ON_GOOD_DEAL"] }}</b-td>
          <b-td>{{ value["NO_SPEND"] }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <ul>
      <li class="my-1">
        <strong>Not enough data:</strong> The client has relevant spending, but no attachments were
        available or the attachments found did not contain the information we need to provide
        quotes. For example the attachment may have been a payment receipt rather than a full
        invoice, or only the first page of the invoice might have been available.
      </li>
      <li class="my-1">
        <strong>In contract:</strong> The client has relevant spending and we found an invoice, but
        they are currently in a long term contract. We can usually provide a quote if the contract
        expires in less than one year.
      </li>
      <li class="my-1">
        <strong>On a good deal:</strong> The client has relevant spending and we found an invoice,
        but they are currently on a good deal and we could not find a better offer.
      </li>
      <li><strong>No spend:</strong> We were unable to identify any spending in this area.</li>
    </ul>
  </b-modal>
</template>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "SavingsExplorerNoQuoteTable",
  props: {
    supplyTypeSummary: Object,
    reportCount: Number
  },
  data() {
    return {};
  },
  methods: {
    formatCoboldCase: FormatHelper.formatCoboldCase
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
</style>